@import "./src/assets/scss/styles.scss";

@import '@/assets/scss/component.scss';
.cookie-consent-banner {
  &__wrapper {
    position: fixed;
    z-index: 1000;
    left: 1rem;
    right: 1rem;
    bottom: 2.5rem;
    display: flex;
    place-content: center;
  }

  &__body {
    font-size: 0.875rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 592px;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 0 0 4px 1px rgba(gray, 0.33);
  }

  &__close {
    display: flex;
    position: absolute;
    top: 1rem;
    right: 1rem;
    svg {
      height: 16px;
      width: 16px;
      // why doesn't this work ? :(
    }
  }

  &__actions {
    justify-content: center;
    grid-gap: 1rem;
    margin-top: 1.5rem;
  }

  .consent-essentials {
    @include button-modal-tertiary;
  }

  .consent-all {
    @include button-modal-primary;
  }
  &__text {
    line-height: 1.4;
    a {
      color: #ef8232 !important;
      text-decoration: underline;
    }
  }
}
