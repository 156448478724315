@import "./src/assets/scss/styles.scss";

.veil {
  // @include set-size(100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: black;
}
