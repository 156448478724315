$primary-color: #ff8300;
$tertiary-color: #1890ff;
$white-color: #fff;

$button-modal-background-primary-color: $primary-color;
$button-modal-background-hover-primary-color: #ffba69;
$button-modal-border-primary-color: $primary-color;
$button-modal-border-hover-primary-color: #ffe3c4;

$button-modal-background-tertiary-color: $tertiary-color;
$button-modal-border-tertiary-color: $tertiary-color;
$button-modal-border-hover-tertiary-color: #d8d8d8;
$button-modal-hover-tertiary-color: #707070;

$button-panel-background-color: #ef8232;
$button-panel-background-color-hover: #c45b00;

@mixin base-button {
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  outline: none;
  vertical-align: top;
}

@mixin side-panel-button {
    @include base-button;
    background: $button-panel-background-color;
    border: none;
    border-radius: 4px;
    color: $white-color;
    font-size: 16px;
    font-weight: 500;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    letter-spacing: 0.0015em;
    padding: 4px 8px;

    &:hover {
      background: $button-panel-background-color-hover;
    }
}

@mixin button-modal {
  @include base-button;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  font-size: 12px;
  font-weight: 400;
  height: 40px;
  min-width: 40px;
  padding: 0 23px;
  text-transform: uppercase;
}

@mixin button-modal-primary {
  @include button-modal;
  background-color: $button-modal-background-primary-color;
  border-color: $button-modal-border-primary-color;
  color: $white-color;
  margin-left: 16px;

  &:hover {
    background-color: $button-modal-background-hover-primary-color;
    border-color: $button-modal-border-hover-primary-color;
  }
}

@mixin button-modal-tertiary {
  @include button-modal;
  background-color: $white-color;
  border-color: $button-modal-border-hover-tertiary-color;
  color: $button-modal-hover-tertiary-color;

  &:hover {
    border-color: $button-modal-border-tertiary-color;
    color: $button-modal-background-tertiary-color;
  }
}